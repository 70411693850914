<template>
    <div class="main-content position-relative max-height-vh-100 h-100">
        <div class="container-fluid py-4">
            <div class="row">
                <div class="col-md-12">
                    <div class="card">
                        <div class="card-header pb-0">
                            <ul class="nav nav-tabs">
                                <li class="nav-item">
                                    <button class="nav-link" :class="{ active: activeTab === 'div_form' }"
                                        @click="showDiv('div_form')">
                                        <i class="fa fa-user"></i> Chamado
                                    </button>
                                </li>
                                <li class="nav-item">
                                    <button class="nav-link" :class="{ active: activeTab === 'div_acoes' }"
                                        @click="showDiv('div_acoes')">
                                        <i class="fa fa-comments"></i> Whatsapp e Email
                                    </button>
                                </li>
                            </ul>
                        </div>
                        <div v-if="loading" class="text-center my-5">
                            <div class="spinner-border text-primary" role="status">
                                <span class="visually-hidden">Carregando...</span>
                            </div>
                        </div>
                        <div v-else style="min-height:60vh" class="card-body">
                            <div v-show="activeTab === 'div_form'">
                                <form @submit.prevent="handleSubmit">
                                    <div class="row">
                                        <div class="col-12">
                                            <p>PROTOCOLO: {{ chamadoId }}</p>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-12">
                                                <label for="cliente_id" class="form-label">Cliente*</label>
                                                <v-select id="cliente_id" class="form-control" v-model="cliente_id"
                                                    :options="clientes" label="display_name"
                                                    placeholder="Selecione o Cliente" required></v-select>
                                            </div>

                                        <div class="col-md-6">
                                            <label for="titulo" class="form-label">Título*</label>
                                            <input id="titulo" v-model="titulo" class="form-control"
                                                placeholder="Título" required />
                                        </div>

                                        <div class="col-md-6">
                                                <label for="celular" class="form-label">Celular*</label>
                                                <input id="celular" v-model="celular" class="form-control"
                                                    placeholder="Celular" required />
                                        </div>


                                        <div class="col-md-6">
                                            <label for="descricao" class="form-label">Descrição*</label>
                                            <textarea rows="9" id="descricao" v-model="descricao" class="form-control"
                                                placeholder="Descrição" required></textarea>
                                        </div>

                                        <div class="col-md-6">
                                            <label for="solucao" class="form-label">Solução*</label>
                                            <textarea rows="9" id="solucao" v-model="solucao" class="form-control"
                                                placeholder="Descreva a descrição da Solução"></textarea>
                                        </div>
                                    </div>
                                    <div class="row fixed-elements">

                                        <div class="col-md-4">
                                            <label for="status" class="form-label">Status*</label>
                                            <select id="status" v-model="status" class="form-control" required
                                                @change="handleStatusChange">
                                                <option value="Aberto">Aberto</option>
                                                <option value="Em Andamento">Em Andamento</option>
                                                <option value="Agendado">Agendado</option>
                                                <option value="Cancelado">Cancelado</option>
                                                <option value="Realizado">Realizado</option>
                                            </select>
                                        </div>

                                        <div class="col-md-4">
                                            <label for="produto_id" class="form-label">Produto</label>
                                            <v-select id="produto_id" class="form-control" v-model="produto_id"
                                                :options="produtos" label="nome" placeholder="Selecione o Produto"
                                                required></v-select>
                                        </div>

                                        <div class="col-md-4">
                                            <label for="categoria_id" class="form-label">Categoria</label>
                                            <v-select id="categoria_id" class="form-control" v-model="categoria_id"
                                                :options="categorias" label="nome" placeholder="Selecione a Categoria"
                                                required></v-select>
                                        </div>
                                    </div>

                                    <div class="row">
                                        <div class="col-md-6">
                                            <label for="data_abertura" class="form-label">Data de Abertura*</label>
                                            <input id="data_abertura" v-model="data_abertura" type="datetime-local"
                                                class="form-control" required />
                                        </div>

                                        <div v-if="status === 'Agendado'" class="col-md-6">
                                            <label for="data_agendamento" class="form-label">Data de Agendamento</label>
                                            <input id="data_agendamento" v-model="data_agendamento"
                                                type="datetime-local" class="form-control"
                                                placeholder="Data de Agendamento" />
                                        </div>

                                        <div v-if="status === 'Cancelado' || status === 'Realizado'" class="col-md-6">
                                            <label for="data_finalizado" class="form-label">Data de
                                                Encerramento</label>
                                            <input id="data_finalizado" v-model="data_finalizado" type="datetime-local"
                                                class="form-control" placeholder="Data de Encerramento" />
                                        </div>

                                        <div v-if="status === 'Aberto' || status === 'Em Andamento'" class="col-md-6">
                                            <label for="data_agendamento_disabled" class="form-label">Data indisponível
                                                neste status*</label>
                                            <div class="form-control disabled-field">
                                                <i class="fa fa-warning" aria-hidden="true"></i>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-12">
                                            <hr>
                                        </div>
                                    </div>
                                    <div class="row mb-3 ">
                                        <div class="col-6">
                                            <router-link to="/Chamados" class="btn btn-default float-start">
                                                <i class="fa fa-arrow-left"></i> Voltar
                                            </router-link>
                                        </div>
                                        <div class="col-6 ">
                                            <div class="d-flex justify-between float-end gap-3" >
                                                <button class="btn btn-primary " @click="ativarConversa()">
                                                    <i class="fa fa-save"></i> Abrir Conversa
                                                </button>
                                                <button class="btn btn-primary " @click="finalizarConversa()">
                                                    <i class="fa fa-save"></i> Finalizar conversa
                                                </button>
                                                <button type="submit" class="btn btn-primary ">
                                                    <i class="fa fa-save"></i> Salvar
                                                </button>
                                            </div>
                          
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <div v-show="activeTab === 'div_acoes'">
                                <form @submit.prevent="handleWhatsAppSubmit">
                                    <div class="row">
                                        <div class="col-md-6">
                                            <div class="row">
                                                <div class="col-12">
                                                    <h4>Envio WhatsApp</h4>
                                                </div>
                                                <div class="col-12">
                                                    <label for="numero_whatsapp" class="form-label">Nº Whatsapp</label>
                                                    <input id="numero_whatsapp" v-model="contato" class="form-control"
                                                        placeholder="Numero Whatsapp" required />
                                                </div>
                                                <div class="col-12">
                                                    <label for="modelo" class="form-label">Modelo</label>
                                                    <select id="modelo" v-model="modelo" class="form-control"
                                                        @change="updateTextWhatsApp" required>
                                                        <option value="">Selecione</option>
                                                        <option value="Seu protocolo de atendimento é {{ chamadoId }}">
                                                            Protocolo Atendimento</option>
                                                        <option
                                                            value="Agendamos o retorno para dia {{ data_agendamento }}">
                                                            Agendamento</option>
                                                        <option
                                                            value="Seu atendimento protocolo {{ chamadoId }} foi encerrado.">
                                                            Encerrado</option>
                                                        <option value="Atendimento cancelado">Cancelado</option>
                                                    </select>
                                                </div>
                                                <div class="col-12">
                                                    <label for="texto_whatsapp" class="form-label">Texto*</label>
                                                    <textarea id="texto_whatsapp" v-model="text_whatsapp"
                                                        class="form-control" placeholder="" required></textarea>
                                                </div>
                                                <div class="col-12">
                                                    <hr>
                                                </div>
                                                <div class="col-12">
                                                    <button type="submit" class="btn btn-primary float-end">
                                                        <i class="fa fa-whatsapp"></i> Enviar Mensagem
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <div v-show="activeTab === 'div_extra'">
                                <div class="row">
                                    <div class="col-12">
                                        <label for="numero_whatsapp" class="form-label">Cobrança</label>
                                        <input id="numero_whatsapp" v-model="contato" class="form-control"
                                            placeholder="Numero Whatsapp" required />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { ref, onMounted } from 'vue';
import axios from '@/axios';
import vSelect from 'vue-select';
import 'vue-select/dist/vue-select.css';
import Swal from 'sweetalert2';
import { useRouter } from 'vue-router';
import { format } from 'date-fns';

export default {
    components: {
        vSelect,
    },
    name: 'EditarChamado',
    props: {
        id: {
            type: String,
            required: true
        }
    },
    setup(props) {
        const router = useRouter();
        const chamadoId = ref(props.id);
        const titulo = ref('');
        const descricao = ref('');
        const solucao = ref('');
        const cliente_id = ref(null);
        const cliente_nome = ref('');
        const status = ref('Aberto');
        const usuario = ref('');
        const data_abertura = ref(format(new Date(), "yyyy-MM-dd'T'HH:mm"));
        const data_agendamento = ref('');
        const data_finalizado = ref('');
        const produto_id = ref(null);
        const categoria_id = ref(null);
        const conversa_id = ref(null);
        const celular = ref('');

        const clientes = ref([]);
        const produtos = ref([]);
        const categorias = ref([]);
        const loading = ref(true);
        const activeTab = ref('div_form');
        const contato = ref('');
        const modelo = ref('');
        const text_whatsapp = ref('');

        onMounted(() => {
            fetchClientes();
            fetchProdutos();
            fetchCategorias();
            fetchChamado();
            setUsuarioId();
        });

        const handleStatusChange = () => {
            if (status.value === 'Realizado' || status.value === 'Cancelado') {
                // Quando o status é "Realizado" ou "Cancelado", formata a data_finalizado sem segundos
                data_finalizado.value = format(new Date(), "yyyy-MM-dd'T'HH:mm");
            } else {
                data_finalizado.value = '';
            }
            if (status.value !== 'Agendado') {
                data_agendamento.value = '';
            }
        };

        const fetchClientes = async () => {
            const response = await axios.get('/clientes');
            clientes.value = response.data.map(cliente => ({
                ...cliente,
                display_name: `${cliente.razao_social} (${cliente.nome_fantasia})`
            }));
        };

        const fetchProdutos = async () => {
            const response = await axios.get('/produtos');
            produtos.value = response.data;
        };

        const fetchCategorias = async () => {
            const response = await axios.get('/categorias');
            categorias.value = response.data;
        };

        const setUsuarioId = () => {
            const user = JSON.parse(localStorage.getItem('user'));
            if (user && user.id) {
                usuario.value = user.name;
            }
        };

        const ativarConversa = async () => {
            try {
                await axios.post(`/conversaativa/${conversa_id.value}`);
                Swal.fire('Sucesso', 'Conversa ativada com sucesso!', 'success');
            } catch (error) {
                Swal.fire('Erro', `Erro ao ativar conversa: ${error.message}`, 'error');
            }
        };

        const finalizarConversa = async () => {
            try {
                await axios.post(`/finalizarconversa/${conversa_id.value}`);
                Swal.fire('Sucesso', 'Conversa finalizada com sucesso!', 'success');
            } catch (error) {
                Swal.fire('Erro', `Erro ao finalizar conversa: ${error.message}`, 'error');
            }
        };

        const fetchChamado = async () => {
            try {
                const response = await axios.get(`/chamados/${chamadoId.value}`);
                const chamado = response.data;


                titulo.value = chamado.titulo;
                descricao.value = chamado.descricao;
                solucao.value = chamado.solucao;
                status.value = chamado.status;
                data_abertura.value = chamado.data_abertura ? chamado.data_abertura.slice(0, 16) : format(new Date(), "yyyy-MM-dd'T'HH:mm");
                data_agendamento.value = chamado.data_agendamento ? chamado.data_agendamento.split('T')[0] : '';
                data_finalizado.value = chamado.data_finalizado ? chamado.data_finalizado.split('T')[0] : '';
                conversa_id.value=chamado.conversa_id
                cliente_id.value = clientes.value.find((cliente)=>cliente.id==chamado.cliente.id);
                cliente_nome.value = chamado.cliente.razao_social;
                produto_id.value = chamado.produto;
                categoria_id.value = chamado.categoria;
                celular.value=chamado.celular;
            } catch (error) {
                Swal.fire({
                    title: 'Erro',
                    text: `Erro ao carregar os dados do chamado. ${error.message}`,
                    icon: 'error',
                    confirmButtonText: 'OK'
                }).then(() => {
                    router.push('/chamados');
                });
            } finally {
                loading.value = false;
            }
        };

        const showDiv = (DivId) => {
            activeTab.value = DivId;
        };

        const handleSubmit = async () => {
            const ticketData = {
                titulo: titulo.value,
                descricao: descricao.value,
                solucao: solucao.value,
                cliente_id: cliente_id.value.id,
                usuario_id: JSON.parse(localStorage.getItem('user')).id,
                status: status.value,
                data_abertura: data_abertura.value,
                data_agendamento: data_agendamento.value,
                data_finalizado: data_finalizado.value,
                produto_id: produto_id.value ? produto_id.value.id : null,
                categoria_id: categoria_id.value ? categoria_id.value.id : null,
                celular:celular.value,
            };

            try {
                await axios.put(`/chamados/${chamadoId.value}`, ticketData);
                Swal.fire({
                    title: 'Sucesso',
                    text: 'Chamado atualizado com sucesso!',
                    icon: 'success',
                    confirmButtonText: 'OK'
                });
            } catch (error) {
                if (error.response && error.response.status === 422) {
                    Swal.fire('Erro', 'Campos inválidos, verifique o preenchimento e tente novamente.', 'error');
                } else {
                    Swal.fire({
                        title: 'Erro',
                        text: `Erro ao atualizar chamado. ${error.message}`,
                        icon: 'error',
                        confirmButtonText: 'OK'
                    }).then(() => {
                        router.push('/chamados');
                    });
                }
                console.log(error);
            }
        };

        const handleWhatsAppSubmit = async () => {
            const number = contato.value.replace(/\D/g, ''); // Remove tudo que não for número
            const msg = text_whatsapp.value;
            const idChamado=chamadoId.value;
            const nomeCliente=cliente_nome.value;

            try {
                await axios.post('/whatsapp/NovaConversa', { number, msg, chamadoId:idChamado, cliente_nome:nomeCliente });
                Swal.fire({
                    title: 'Sucesso',
                    text: 'Mensagem enviada com sucesso!',
                    icon: 'success',
                    confirmButtonText: 'OK'
                });
            } catch (error) {
                Swal.fire({
                    title: 'Erro',
                    text: `Erro ao enviar mensagem. ${error.message}`,
                    icon: 'error',
                    confirmButtonText: 'OK'
                });
            }
        };

        const updateTextWhatsApp = () => {
            switch (modelo.value) {
                case 'Seu protocolo de atendimento é {{ chamadoId }}':
                    text_whatsapp.value = `Seu protocolo de atendimento é ${chamadoId.value}`;
                    break;
                case 'Agendamos o retorno para dia {{ data_agendamento }}':
                    text_whatsapp.value = `Agendamos o retorno para dia ${data_agendamento.value}`;
                    break;
                case 'Seu atendimento protocolo {{ chamadoId }} foi encerrado.':
                    text_whatsapp.value = `Seu atendimento protocolo ${chamadoId.value} foi encerrado.`;
                    break;
                case 'Atendimento cancelado':
                    text_whatsapp.value = 'Atendimento cancelado';
                    break;
                default:
                    text_whatsapp.value = '';
                    break;
            }
        };

        return {
            chamadoId,
            titulo,
            descricao,
            solucao,
            usuario,
            cliente_id,
            cliente_nome,
            status,
            data_abertura,
            data_agendamento,
            data_finalizado,
            produto_id,
            categoria_id,
            conversa_id,
            celular,
            clientes,
            produtos,
            categorias,
            loading,
            activeTab,
            contato,
            modelo,
            text_whatsapp,
            showDiv,
            handleSubmit,
            handleStatusChange,
            ativarConversa,
            finalizarConversa,
            fetchChamado,
            handleWhatsAppSubmit,
            updateTextWhatsApp

        };
    },
};
</script>

<style scoped>
.nav-link.active {
    font-weight: bold;
}
</style>
