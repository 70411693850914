<template>
    <div class="main-content position-relative max-height-vh-100 h-100">
        <div class="container-fluid py-4">
            <div class="row">
                <div class="col-md-12">
                    <div class="card">
                        <div class="card-header pb-0">
                            <div class="d-flex align-items-center">
                                <p class="mb-0">Lista de Chamados</p>
                                <router-link to="/Abrirchamado" class="btn btn-primary btn-sm ms-auto"><i
                                        class="fa fa-plus"></i> Adicionar Chamado</router-link>
                            </div>
                        </div>
                        <div style="min-height:60vh" class="card-body">
                            <div class="row">
                                <div class="col-12 table-responsive">
                                    <div v-if="loading" class="text-center">
                                        <i class="fa fa-spinner fa-spin fa-3x"></i>
                                        <p>Carregando...</p>
                                    </div>
                                    <table v-else id="ticketsTable" class="table table-hover">
                                        <thead>
                                            <tr>
                                                <th scope="col">#</th>
                                                <th scope="col">Título</th>
                                                <th scope="col">Atendente</th>
                                                <th scope="col">Cliente</th>
                                                <th scope="col">Status</th>
                                                <th scope="col">Data de Abertura</th>
                                                <th scope="col">Produto</th>
                                                <th scope="col">Categoria</th>
                                                <th scope="col">Último Update</th>
                                            </tr>
                                        </thead>
                                        <tbody style="cursor:pointer">
                                            <tr v-for="(ticket) in tickets" :key="ticket.id"
                                                @click="goToClient(ticket.id)">
                                                <th scope="row">{{ ticket.id }}</th>
                                                <td>{{ ticket.titulo }}</td>
                                                <td>{{ ticket.usuario.nome }}</td>
                                                <td>{{ ticket.cliente.razao_social }}</td>
                                                <td :class="statusClass(ticket.status)">
                                                    <i :class="statusIcon(ticket.status)"></i>
                                                    {{ ticket.status }}
                                                </td>
                                                <td>{{ ticket.created_at ? new
                                                    Date(ticket.created_at).toLocaleDateString() : 'Não disponível' }}
                                                </td>
                                                <td>{{ ticket.produto.nome }}</td>
                                                <td>{{ ticket.categoria.nome }}</td>
                                                <td>{{ ticket.updated_at ? new
                                                    Date(ticket.updated_at).toLocaleDateString() : 'Não disponível' }}
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from '@/axios';
import $ from 'jquery'; // Importar jQuery
import 'datatables.net'; // Importar DataTables
//import 'datatables.net-dt/css/jquery.dataTables.css'; // Importar CSS do DataTables

export default {
    data() {
        return {
            tickets: [],
            loading: true
        };
    },
    async mounted() {
        await this.fetchTickets();
        this.$nextTick(() => {
            $('#ticketsTable').DataTable({
                order: [[0, 'desc']],
                language: {
                    search: "Pesquisar:",
                    lengthMenu: "Mostrar _MENU_ Chamados",
                    info: "Mostrando _START_ a _END_ de _TOTAL_ chamados",
                    infoEmpty: "Mostrando 0 a 0 de 0 entradas",
                    infoFiltered: "(filtrado de _MAX_ entradas totais)",
                    loadingRecords: "Carregando...",
                    zeroRecords: "Nenhum registro encontrado",
                    emptyTable: "Nenhum dado disponível na tabela",
                    paginate: {
                        first: "Primeiro",
                        previous: "Anterior",
                        next: "Próximo",
                        last: "Último"
                    },
                    aria: {
                        sortAscending: ": ativar para ordenar a coluna em ordem crescente",
                        sortDescending: ": ativar para ordenar a coluna em ordem decrescente"
                    }
                }
            });
        });
    },
    methods: {
        async fetchTickets() {
            try {
                this.loading = true;
                const response = await axios.get('/chamados');
                this.tickets = response.data;
            } catch (error) {
                console.error('Erro ao buscar chamados:', error);
            } finally {
                this.loading = false;
            }
        },
        goToClient(clientId) {
            this.$router.push(`/EditarChamado/${clientId}`);
        },
        editTicket(ticketId) {
            this.$router.push(`/EditarChamado/${ticketId}`);
        },
        statusClass(status) {
            switch (status) {
                case 'Aberto':
                    return 'text-primary';
                case 'Em Andamento':
                    return 'text-warning';
                case 'Agendado':
                    return 'text-info';
                case 'Cancelado':
                    return 'text-danger';
                case 'Realizado':
                    return 'text-success';
                default:
                    return '';
            }
        },
        statusIcon(status) {
            switch (status) {
                case 'Aberto':
                    return 'fa fa-folder-open';
                case 'Em Andamento':
                    return 'fa fa-spinner';
                case 'Agendado':
                    return 'fa fa-calendar';
                case 'Cancelado':
                    return 'fa fa-times-circle';
                case 'Realizado':
                    return 'fa fa-check-circle';
                default:
                    return '';
            }
        }
    }
};
</script>

<style scoped>
.table {
    width: 100%;
    margin-bottom: 1rem;
    color: #212529;
    border-collapse: collapse;
}

.table th,
.table td {
    padding: 0.75rem;
    vertical-align: top;
    border-top: 1px solid #dee2e6;
}

.table thead th {
    vertical-align: bottom;
    border-bottom: 2px solid #dee2e6;
}

.table tbody+tbody {
    border-top: 2px solid #dee2e6;
}

.table .table {
    background-color: #fff;
}

.text-center {
    text-align: center;
    padding: 2rem;
}

.text-primary {
    color: #007bff !important;
}

.text-warning {
    color: #ffc107 !important;
}

.text-info {
    color: #17a2b8 !important;
}

.text-danger {
    color: #dc3545 !important;
}

.text-success {
    color: #28a745 !important;
}
</style>
