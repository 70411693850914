<template>
    <main class="main-content  mt-0">
        <section>
            <div class="page-header min-vh-100">
                <div class="container">
                    <div class="row">
                        <div class="col-xl-4 col-lg-5 col-md-7 d-flex flex-column mx-lg-0 mx-auto">
                            <div class="card card-plain bg-white">
                                <div class="card-header pb-0 text-center">
                                    <h4 class="font-weight-bolder">APP Suporte</h4>
                                    <img style="max-height: 70px;" src="/img/logo.png">
                                    <p class="mb-0">Digite seu email e senha</p>
                                </div>
                                <div class="card-body">
                                    <form @submit.prevent="login">
                                        <div class="mb-3">
                                            <label>Email</label>
                                            <input v-model="email" placeholder="Email"
                                                class="form-control form-control-lg" type="email" id="email" required />
                                        </div>
                                        <div class="mb-3">
                                            <label>Senha</label>
                                            <input v-model="password" placeholder="Senha"
                                                class="form-control form-control-lg" type="password" id="password"
                                                required />
                                        </div>
                                        <div class="form-check form-switch">
                                            <input class="form-check-input" type="checkbox" id="rememberMe">
                                            <label class="form-check-label" for="rememberMe">Lembrar-me</label>
                                        </div>
                                        <div class="text-center">
                                            <button class="btn btn-primary w-100 mt-3" type="submit">Login</button>
                                        </div>
                                    </form>
                                </div>
                                <!-- <div class="card-footer text-center pt-0 px-lg-2 px-1">
                                    <p class="mb-4 text-sm mx-auto">
                                        Don't have an account?
                                        <a href="javascript:;" class="text-primary text-gradient font-weight-bold">Sign
                                            up</a>
                                    </p>
                                </div> -->
                            </div>
                        </div>
                        <div
                            class="col-6 d-lg-flex d-none h-100 my-auto pe-0 position-absolute top-0 end-0 text-center justify-content-center flex-column">
                            <div class="position-relative bg-gradient-primary h-100 m-3 px-7 border-radius-lg d-flex flex-column justify-content-center overflow-hidden"
                                style="background-image: url('/img/bg2.png');
          background-size: cover;">
                                <span class="mask bg-gradient-primary opacity-6"></span>
                                <h4 class="mt-5 text-white font-weight-bolder position-relative">Keep Calm</h4>
                                <p class="text-white position-relative">Cbm informática</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </main>
</template>
<script>
import axios from '../axios'; // Importar a configuração do axios
import Swal from 'sweetalert2';
import { useRouter } from 'vue-router'; // Importar useRouter para usar no Composition API

export default {
    data() {
        return {
            email: '',
            password: ''
        };
    },
    methods: {
        async login() {
            try {
                const response = await axios.post('/login', {
                    email: this.email,
                    password: this.password
                });
                const token = response.data.authorization.token;
                console.log(token);
                localStorage.setItem('jwt', token); // Armazenar o token no localStorage
                localStorage.setItem('user', JSON.stringify(response.data.user));
                this.$router.push('/'); // Redirecionar para a página inicial
            } catch (error) {
                console.error('Login failed:', error.response ? error.response.data : error.message);

                if (error.response && error.response.status === 401) {
                    Swal.fire({
                        title: 'Erro login',
                        text: "Usuário ou senha inválidos",
                        icon: 'info',
                        confirmButtonText: 'Ok'
                    });
                } else {
                    Swal.fire({
                        title: 'Erro login',
                        text: error.response ? error.response.data.message : 'Erro desconhecido',
                        icon: 'error',
                        confirmButtonText: 'Ok'
                    });
                }
            }
        }
    },
    setup() {
        const router = useRouter(); // Usar useRouter para acessar o router
        return { router };
    }
};
</script>