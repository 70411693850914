import { createRouter, createWebHistory } from 'vue-router';
import HomePage from '../pages/HomePage.vue';
import LoginPage from '../pages/LoginPage.vue';
import ProdutosPage from '../pages/ProdutosPage.vue';
import NotFoundPage from '../pages/NotFoundPage.vue';
import CategoriasPage from '../pages/CategoriasPage.vue';
import ClientesPage from '../pages/ClientesPage.vue';
import ChamadosPage from '../pages/ChamadosPage.vue';
import AbrirChamado from '../pages/AbrirChamado.vue';
import EditarChamado from '../pages/EditarChamado.vue';
import CadastroCliente from '../pages/CadastroCliente.vue';
import EditarCliente from '../pages/EditarCliente.vue';
import UsuariosPage from '../pages/UsuariosPage.vue';
import AbrirOS from '@/pages/AbrirOS.vue';
import EditarOS from '@/pages/EditarOS.vue';
import OrdensServicoCriados from '@/pages/OrdensServicoCriados.vue';
import ImpressaoOS from '../components/ImpressaoOS.vue';

const routes = [
    {
        path: '/home',
        name: 'Home',
        component: HomePage,
        meta: { requiresAuth: true } // Indica que a rota precisa de autenticação
    },
    {
        path: '/usuarios',
        name: 'Usuarios',
        component: UsuariosPage,
        meta: { requiresAuth: true } // Indica que a rota precisa de autenticação
    },
    {
        path: '/',
        name: 'Home',
        component: HomePage,
        meta: { requiresAuth: true } // Indica que a rota precisa de autenticação
    },
    {
        path: '/EditarCliente/:id',
        name: 'EditarCliente',
        component: EditarCliente,
        props: true,
        meta: { requiresAuth: true } // Indica que a rota precisa de autenticação
    },
    {
        path: '/Produtos',
        name: 'ProdutosPage',
        component: ProdutosPage,
        meta: { requiresAuth: true } // Indica que a rota precisa de autenticação
    },
    {
        path: '/EditarChamado/:id',
        name: 'EditarChamado',
        component: EditarChamado,
        props: true,
        meta: { requiresAuth: true }
    },
    {
        path: '/AbrirChamado',
        name: 'AbrirChamado',
        component: AbrirChamado,
        meta: { requiresAuth: true } // Indica que a rota precisa de autenticação
    },
    {
        path: '/OrdensServicoCriados',
        name: 'Ordens de Serviço',
        component: OrdensServicoCriados,
        meta: { requiresAuth: true } // Indica que a rota precisa de autenticação
    },
    {
        path: '/AbrirOS',
        name: 'Ordem de Serviço',
        component: AbrirOS,
        meta: { requiresAuth: true } // Indica que a rota precisa de autenticação
    },
    {
        path: '/EditarOS/:id',
        name: 'Editar Ordem de Serviço',
        component: EditarOS,
        meta: { requiresAuth: true } // Indica que a rota precisa de autenticação
    },
    {
        path: '/categorias',
        name: 'Categorias',
        component: CategoriasPage,
    },
    {
        path: '/chamados',
        name: 'Chamados',
        component: ChamadosPage,
    },
    {
        path: '/clientes',
        name: 'Clientes',
        component: ClientesPage,
    },
    {
        path: '/CadastroCliente',
        name: 'CadastroCliente',
        component: CadastroCliente,
    },    
    {   path: '/print/:id',
        name: 'Print',
        component: ImpressaoOS
    },
    {
        path: '/login',
        name: 'Login',
        component: LoginPage
    },
    {
        path: '/404',
        name: '404',
        component: NotFoundPage
    },
    // Outras rotas
    {
        path: '/:pathMatch(.*)*', // Captura todas as rotas não definidas
        redirect: { name: '404' } // Redireciona para a página de login
    },
];

const router = createRouter({
    history: createWebHistory(),
    routes
});

router.beforeEach((to, from, next) => {
    const isAuthenticated = !!localStorage.getItem('jwt'); // Verifica se há um token armazenado

    if (to.meta.requiresAuth && !isAuthenticated) {
        next({ name: 'Login' }); // Redireciona para a página de login se não autenticado
    } else {
        next(); // Permite a navegação se autenticado ou se a rota não requer autenticação
    }
});

export default router;