<template>
    <div class="container-fluid py-4">
        <div class="row">
            <div class="col-md-12">
                <div class="card">
                    <div class="card-header pb-0">
                        <ul class="nav nav-tabs">
                            <li class="nav-item">
                                <button class="nav-link" :class="{ active: activeTab === 'div_form' }"
                                    @click="showDiv('div_form')">
                                    <i class="fa fa-user"></i> Cadastro
                                </button>
                            </li>
                            <li class="nav-item">
                                <button class="nav-link" :class="{ active: activeTab === 'div_arquivos' }"
                                    @click="showDiv('div_arquivos')">
                                    <i class="fa fa-file"></i> Upload
                                </button>
                            </li>
                        </ul>
                    </div>
                    <div class="card-body">
                        <div v-show="activeTab === 'div_form'">
                            <form @submit.prevent="handleSubmit">
                                <div class="row">
                                    <div class="col-md-6">
                                        <label for="razao_social" class="form-label">Razão Social*</label>
                                        <input id="razao_social" v-model="formData.razao_social" class="form-control"
                                            placeholder="Razão Social" required />
                                    </div>

                                    <div class="col-md-6">
                                        <label for="nome_fantasia" class="form-label">Nome Fantasia*</label>
                                        <input id="nome_fantasia" v-model="formData.nome_fantasia" class="form-control"
                                            placeholder="Nome Fantasia" required />
                                    </div>

                                    <div class="col-md-6">
                                        <label for="cnpj" class="form-label">CNPJ*</label>
                                        <input id="cnpj" v-model="formData.cnpj" class="form-control" placeholder="CNPJ"
                                            required />
                                    </div>

                                    <div class="col-md-6">
                                        <label for="endereco" class="form-label">Endereço*</label>
                                        <input id="endereco" v-model="formData.endereco" class="form-control"
                                            placeholder="Endereço" required />
                                    </div>

                                    <div class="col-md-12">
                                        <label for="obs" class="form-label">Observações / Anotações*</label>
                                        <textarea id="obs" style="min-height: 200px;" v-model="formData.obs"
                                            class="form-control" placeholder="Descrição" required></textarea>
                                    </div>

                                    <div class="col-md-6">
                                        <label for="email" class="form-label">Email*</label>
                                        <input id="email" v-model="formData.email" class="form-control"
                                            placeholder="Email" required />
                                    </div>

                                    <div class="col-md-6">
                                        <label for="telefone" class="form-label">Telefone*</label>
                                        <input id="telefone" v-model="formData.telefone" class="form-control"
                                            placeholder="Telefone" required />
                                    </div>
                                </div>

                                <div class="row mb-3">
                                    <div class="col-12">
                                        <hr />
                                    </div>
                                    <div class="col-6">
                                        <router-link to="/Clientes" class="btn btn-default float-start btn-lg">
                                            <i class="fa fa-arrow-left"></i> Voltar
                                        </router-link>
                                    </div>
                                    <div class="col-6">
                                        <button type="submit" class="btn btn-primary float-end btn-lg">
                                            <i class="fa fa-save"></i> Salvar
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>

                        <div v-show="activeTab === 'div_arquivos'" class="painel">
                            <form @submit.prevent="handleSubmitFiles">
                                <div class="row">
                                    <div class="col-md-4">
                                        <label for="file_name" class="form-label">Nome do Anexo*</label>
                                        <input id="file_name" v-model="fileName" class="form-control"
                                            placeholder="Nome do Anexo" required />
                                    </div>
                                    <div class="col-md-4">
                                        <label for="file" class="form-label">Arquivo</label>
                                        <input id="file" class="form-control" type="file"
                                            accept="video/mp4, image/*, application/pdf" @change="handleFileChange"
                                            required />
                                    </div>
                                    <div class="col-md-4">
                                        <br>
                                        <button type="submit" class="btn btn-primary float-end">
                                            <i class="fa fa-save"></i> Enviar Anexo
                                        </button>
                                    </div>
                                </div>
                                <div class="row">
                                    <hr />
                                </div>
                                <div v-if="files.length" class="row">
                                    <!-- Lista de arquivos -->
                                    <div v-for="file in files" :key="file.id" class="col-md-4">
                                        <div class="file-man-box">
                                            <a :href="file.url" class="file-download" target="_blank">
                                                <i class="fa fa-download"></i>
                                            </a>
                                            <div class="file-img-box">
                                                <i class="fa fa-file"></i>
                                            </div>
                                            <div class="file-man-title">
                                                <h5 class="mb-0 text-overflow">{{ file.name }}</h5>
                                                <p class="mb-0"><small>{{ formatDate(file.uploaded_at) }}</small></p>
                                                <p class="mb-0"><small>{{ file.client_name }}</small></p>
                                            </div>
                                            <button type="button" @click="deleteFile(file.id)"
                                                class="btn btn-danger btn-sm">
                                                <i class="fa fa-times"></i> Deletar
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div class="row mb-3">
                                    <div class="col-12">
                                        <hr />
                                    </div>
                                    <div class="col-6">
                                        <router-link to="/Clientes" class="btn btn-default float-start btn-lg">
                                            <i class="fa fa-arrow-left"></i> Voltar
                                        </router-link>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from '@/axios';
import Swal from 'sweetalert2';
import { API_BASE_URL } from '@/config';

export default {
    data() {
        return {
            formData: {
                razao_social: '',
                nome_fantasia: '',
                cnpj: '',
                endereco: '',
                obs: '',
                email: '',
                telefone: ''
            },
            activeTab: 'div_form', // Controle da aba ativa
            file: null, // Arquivo selecionado
            fileName: '', // Nome do arquivo
            filePath: '', // Caminho do arquivo após upload
            fileSize: '', // Tamanho do arquivo
            files: [], // Lista de arquivos
            apiBaseUrl: API_BASE_URL
        };
    },
    created() {
        this.fetchClienteData();
        this.fetchFiles(); // Carregar arquivos ao inicializar
    },
    methods: {
        async fetchClienteData() {
            try {
                const clienteId = this.$route.params.id; // Assume que o ID do cliente está na rota
                const response = await axios.get(`/clientes/${clienteId}`); // Atualize a URL para /api/clientes/
                this.formData = response.data;
            } catch (error) {
                Swal.fire('Erro', 'Erro ao buscar dados do cliente.', 'error');
            }
        },
        async fetchFiles() {
            try {
                const clientId = this.$route.params.id;
                const response = await axios.get(`/files?client_id=${clientId}`);
                this.files = response.data.map(file => ({
                    ...file,
                    url: `${this.apiBaseUrl}${file.url}`
                }));
            } catch (error) {
                Swal.fire('Erro', 'Erro ao buscar arquivos.', 'error');
            }
        },
        async deleteFile(fileId) {
            // Exibe a caixa de diálogo de confirmação
            const result = await Swal.fire({
                title: 'Tem certeza?',
                text: "Você não poderá reverter isso!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Sim, deletar!',
                cancelButtonText: 'Cancelar'
            });

            // Verifica se o usuário confirmou a exclusão
            if (result.isConfirmed) {
                try {
                    // Faz a requisição DELETE
                    const response = await axios.delete(`/files/${fileId}`);
                    Swal.fire('Deletado!', response.data.message, 'success');
                    this.fetchFiles(); // Atualiza a lista de arquivos após a exclusão
                } catch (error) {
                    Swal.fire('Erro', 'Erro ao deletar arquivo.', 'error');
                }
            }
        },
        async handleSubmit() {
            try {
                const clienteId = this.$route.params.id; // Assume que o ID do cliente está na rota
                await axios.put(`/clientes/${clienteId}`, this.formData); // Atualize a URL para /api/clientes/
                Swal.fire('Sucesso', 'Cliente atualizado com sucesso!', 'success');
            } catch (error) {
                Swal.fire('Erro', 'Erro ao atualizar cliente.', 'error');
            }
        },
        async handleSubmitFiles() {
            if (!this.file) {
                Swal.fire('Erro', 'Nenhum arquivo selecionado.', 'error');
                return;
            }

            const formData = new FormData();
            formData.append('file', this.file);
            formData.append('name', this.fileName);
            formData.append('client_id', this.$route.params.id);

            // Exibir Swal com barra de progresso
            Swal.fire({
                title: 'Enviando arquivo...',
                html: '<div id="progress-container" style="width: 100%;"><div id="progress-bar" style="width: 0%; height: 20px; background-color: #007bff;"></div></div>',
                showConfirmButton: false,
                allowOutsideClick: false,
                didOpen: () => {
                    Swal.showLoading();
                }
            });

            try {
                // Envio de arquivo com Axios e monitoramento do progresso
                await axios.post('/upload', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    },
                    onUploadProgress: progressEvent => {
                        const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                        // Atualiza a barra de progresso
                        document.getElementById('progress-bar').style.width = `${percentCompleted}%`;
                    }
                });

                // Atualiza Swal com mensagem de sucesso após o upload
                Swal.fire({
                    title: 'Sucesso',
                    text: 'Arquivo enviado com sucesso!',
                    icon: 'success',
                    confirmButtonText: 'OK'
                });

                this.fetchFiles(); // Atualiza a lista de arquivos após o upload
            } catch (error) {
                // Atualiza Swal com mensagem de erro
                Swal.fire({
                    title: 'Erro',
                    text: 'Erro ao enviar arquivo.',
                    icon: 'error',
                    confirmButtonText: 'OK'
                });
            }
        },
        handleFileChange(event) {
            this.file = event.target.files[0];
            //this.fileName = this.file ? this.file.name : '';
            this.fileSize = this.file ? `${(this.file.size / 1024).toFixed(2)} KB` : '';
        },
        showDiv(DivId) {
            this.activeTab = DivId;
        },
        formatDate(date) {
            return new Date(date).toLocaleDateString('pt-BR');
        }
    }
};
</script>
<style scoped>
.nav-link.active {
    font-weight: bold;
}

.painel {
    min-height: 60vh;
}

.file-man-box {
    padding: 20px;
    border: 1px solid #e3eaef;
    border-radius: 5px;
    position: relative;
    margin-bottom: 20px;
}

.file-man-box .file-close {
    color: #f1556c;
    position: absolute;
    line-height: 24px;
    font-size: 24px;
    right: 10px;
    top: 10px;
    visibility: hidden;
}

.file-man-box .file-img-box {
    line-height: 120px;
    text-align: center;
}

.file-man-box .file-img-box img {
    height: 64px;
}

.file-man-box .file-download {
    font-size: 32px;
    color: #98a6ad;
    position: absolute;
    right: 10px;
}

.file-man-box .file-download:hover {
    color: #313a46;
}

.file-man-box .file-man-title {
    padding-right: 25px;
}

.file-man-box:hover {
    -webkit-box-shadow: 0 0 24px 0 rgba(0, 0, 0, .06), 0 1px 0 0 rgba(0, 0, 0, .02);
    box-shadow: 0 0 24px 0 rgba(0, 0, 0, .06), 0 1px 0 0 rgba(0, 0, 0, .02);
}

.file-man-box:hover .file-close {
    visibility: visible;
}

.text-overflow {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}
</style>
