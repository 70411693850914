<template>
    <div class="container-fluid py-4">
        <div class="row">
            <div class="col-md-12">
                <div class="card">
                    <div class="card-header pb-0">
                        <div class="d-flex align-items-center">
                            <p class="mb-0">Lista de Clientes</p>
                            <router-link to="/CadastroCliente" class="btn btn-primary btn-sm ms-auto"><i
                                    class="fa fa-plus"></i> Cadastrar Cliente</router-link>
                        </div>
                    </div>
                    <div style="min-height:60vh" class="card-body">
                        <div v-if="clients.length" class="table-responsive">
                            <table id="clientsTable" class="table table-hover">
                                <thead>
                                    <tr>
                                        <th scope="col">#</th>
                                        <th scope="col">Razão Social</th>
                                        <th scope="col">Nome Fantasia</th>
                                        <th scope="col">CNPJ</th>
                                        <th scope="col">Endereço</th>
                                        <th scope="col">Email</th>
                                        <th scope="col">Telefone</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(client, index) in clients" :key="client.id"
                                        @click="goToEditClient(client.id)" style="cursor: pointer;">
                                        <th scope="row">{{ index + 1 }}</th>
                                        <td>{{ client.razao_social }}</td>
                                        <td>{{ client.nome_fantasia }}</td>
                                        <td>{{ client.cnpj }}</td>
                                        <td>{{ client.endereco }}</td>
                                        <td>{{ client.email }}</td>
                                        <td>{{ client.telefone }}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div v-else class="text-center">
                            <i class="fa fa-spinner fa-spin fa-3x"></i>
                            <p>Carregando...</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from '@/axios';
import $ from 'jquery'; // Importar jQuery
import 'datatables.net'; // Importar DataTables

export default {
    data() {
        return {
            clients: [],
            loading: true // Variável para controlar o estado de carregamento
        };
    },
    async mounted() {
        await this.fetchClients();
        this.$nextTick(() => {
            $('#clientsTable').DataTable({
                // Adicione opções de configuração do DataTables aqui, se necessário
            });
        });
    },
    methods: {
        async fetchClients() {
            try {
                const response = await axios.get('/clientes');
                this.clients = response.data;
            } catch (error) {
                console.error('Erro ao buscar clientes:', error);
            } finally {
                this.loading = false; // Termina o carregamento
            }
        },
        goToEditClient(clientId) {
            this.$router.push(`/EditarCliente/${clientId}`);
        }
    }
};
</script>

<style scoped>
.main-content {
    padding-top: 4rem;
}
</style>
